@font-face {
  font-family: BlacCar;
  font-weight: 400;
  src: url('./fonts/NotoSans-Regular.ttf');
}
@font-face {
  font-family: BlacCar;
  font-weight: 500;
  src: url('./fonts/NotoSans-Medium.ttf');
}
@font-face {
  font-family: BlacCar;
  font-weight: 600;
  src: url('./fonts/NotoSans-Bold.ttf');
}
@font-face {
  font-family: BlacCar;
  font-weight: 800;
  src: url('./fonts/NotoSans-Black.ttf');
}
@font-face {
  font-family: BlacCar;
  font-style: italic;
  src: url('./fonts/NotoSans-Italic.ttf');
}
@font-face {
  font-family: BlacCar;
  font-weight: 600;
  font-style: italic;
  src: url('./fonts/NotoSans-BoldItalic.ttf');
}
body {
  margin: 0;
  font-family: 'BlacCar',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #dee3e7;
}

