$--border-radius: .3rem;
.category{
  display: flex;
  margin: 1rem 0;
  .container{
    display: flex;
    .filters{
      .catlist{
        background: #fff;
        border-radius: $--border-radius;
        padding: 1rem 2rem;
        a {
          text-decoration: none;
          color: #0f0f0f;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          &:hover{
            color: #475e88;
          }
          li {
            max-width: 300px;
            list-style: circle;
            margin: .6rem 1rem;
          }
        }
      }
    }
    .product-filter{
      display: none;
      margin: 1rem 0 0 0;
      flex: 0 20%;
      @media screen and (min-width : 480px) {
        display: flex;
      }
    }
    .items {
      flex: 0 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .product-item {
        margin: 1rem;
        flex: 0 100%;
        background: #fff;
        border-radius: $--border-radius;
        height: fit-content;
        @media screen and (min-width : 480px) {
          margin: 1rem 0 0 1rem;
          flex: 0 calc(33.33% - 1rem);
        }
        a{text-decoration: none;
        &.imagelink{
          width: 100%;
          height: 150px;
          overflow: hidden;
          display: block;
        }
        }
        img {
          border-radius: $--border-radius $--border-radius 0 0 ;
          width: 100%;
        }
        .info{
          padding: 0 1rem 1rem 1rem;
          h3 {
            font-size: 1rem;
            font-weight: 500;
          }
          .product-footer{
            margin-top: 2rem;
            .product-price{
              font-weight: 600;
              font-size: 1rem;
              &:after{
                content: " грн";
                text-transform: uppercase;
                font-size: .6rem;
              }
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

      }
    }
  }
}
