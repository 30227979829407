.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .container {
    flex-direction: column;
    @media screen and (min-width : 480px) {
      flex-direction: row;
    }
    .footer-bottom {
      display: flex;
      border-top: 1px solid #c5c7cb;
      width: 100%;
      justify-content: center;
      padding: 1rem 0;


      span {
        font-size: .8rem;
        color: #797c8a;
      }
    }

    .footer-logo {
      width: 90px;
      margin-right: 1rem;
    }

    .left-items {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      flex: 0 25%;
      color: #3e404d;
      font-size: .8rem;
      @media screen and (min-width : 480px) {
        flex-direction: row;
      }
    }

    .central-items {
      display: flex;
      flex: 0 45%;
      justify-content: center;
      align-items: center;

      img {
        opacity: .8;
        margin: 1rem;
        height: 30px;

        &:nth-child(2) {
          height: 20px;
        }
      }
    }

    .right-items {
      display: flex;
      flex: 0 30%;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      @media screen and (min-width : 480px) {
        flex-direction: row;
      }
      span {
        color: #3e404d;
        font-size: .8rem;
        margin: 1rem;
      }
    }
  }
}
