$--border-radius: .3rem;
.category{
  display: flex;
  margin: 1rem 0;
  .container{
    display: flex;
    .product-filter{
      display: none;
      margin: 1rem 0 0 0;
      flex: 0 20%;
      @media screen and (min-width : 480px) {
        display: flex;
      }
    }
    .items {
      flex: 0 100%;
      display: flex;
      flex-wrap: wrap;
      @media screen and (min-width : 480px) {
        flex: 0 80%;
      }
      .product-item {
        margin: 1rem;
        flex: 0 100%;
        background: #fff;
        border-radius: $--border-radius;
        @media screen and (min-width : 480px) {
          margin: 1rem 0 0 1rem;
          flex: 0 calc(33.33% - 1rem);
        }
        a{text-decoration: none;}
        .info{
          padding: 0 1rem 1rem 1rem;
          h3 {
            font-size: 1rem;
            font-weight: 500;
          }
          .product-footer{
            margin-top: 2rem;
            .product-price{
              font-weight: 600;
              font-size: 1rem;
              &:after{
                content: " грн";
                text-transform: uppercase;
                font-size: .6rem;
              }
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}
