@charset "UTF-8";
.popup-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.popup-overlay .overlay-bg {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.35);
  z-index: 998;
}
.popup-overlay .popup {
  left: 0;
  top: 0;
  width: 70%;
  max-height: 60vh;
  padding: 2rem;
  position: relative;
  z-index: 1000;
  background: #fff;
  border-radius: 0.3rem;
}
.popup-overlay .popup .popup-header {
  display: flex;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
}
.popup-overlay .popup .popup-header svg {
  font-size: 1.6rem;
  cursor: pointer;
}
.popup-overlay .popup .popup-body .popup-cart .products-list {
  overflow-y: scroll;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  max-height: 40vh;
  border-radius: 0.3rem;
  padding: 0.4rem;
  background: #f0f3f5;
}
.popup-overlay .popup .popup-body .popup-cart .products-list .cart-popup-product {
  margin: 0.4rem;
  padding: 0.4rem;
  background: #fff;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.popup-overlay .popup .popup-body .popup-cart .products-list .cart-popup-product .title {
  flex: 0 calc(50% - 1rem);
  margin-left: 1rem;
}
.popup-overlay .popup .popup-body .popup-cart .products-list .cart-popup-product .prod-code {
  flex: 0 calc(25% - 1rem);
  margin-left: 1rem;
}
.popup-overlay .popup .popup-body .popup-cart .products-list .cart-popup-product .quantity {
  flex: 0 calc(10% - 1rem);
  margin-left: 1rem;
  min-width: 50px;
}
.popup-overlay .popup .popup-body .popup-cart .products-list .cart-popup-product .delete {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex: 0 5%;
  font-size: 1.2rem;
  min-width: 40px;
}
.popup-overlay .popup .popup-body .popup-cart .products-list .cart-popup-product img {
  flex: 0 10%;
  width: 10%;
  height: auto;
}
.popup-overlay .popup .popup-body .popup-cart .cart-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}
.popup-overlay .popup .popup-body .popup-cart .cart-footer .right-items {
  display: flex;
  align-items: center;
}
.popup-overlay .popup .popup-body .popup-cart .cart-footer .cart-total {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1rem;
  margin-right: 1rem;
}
.popup-overlay .popup .popup-body .popup-cart .cart-footer .cart-total .total {
  font-size: 1.2rem;
  font-weight: 600;
}
.popup-overlay .popup .popup-body .popup-cart .cart-footer .cart-total .total:after {
  content: " грн";
  text-transform: uppercase;
  font-size: 0.6rem;
}

