@charset "UTF-8";
* {
  transition: all 0.3s;
}

.header-wrapper {
  background: #1b2028;
  background: radial-gradient(circle, #192c49 0%, #1d2533 50%, #1b2028 100%);
}

.header-top {
  display: flex;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8rem;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0 0 0;
}
@media screen and (min-width: 480px) {
  .header-top {
    padding: 1rem 0 0 0;
  }
}
.header-top svg {
  margin-right: 0.3rem;
  color: #ffd400;
  font-size: 1rem;
}
.header-top .container {
  flex-direction: column-reverse;
  align-items: center;
}
.header-top .container div {
  margin: 0.6rem 0;
}
@media screen and (min-width: 480px) {
  .header-top .container {
    flex-direction: row;
  }
}
.header-top .container .left-items {
  flex: 1 30%;
  display: none;
}
@media screen and (min-width: 480px) {
  .header-top .container .left-items {
    display: flex;
  }
}
.header-top .container .left-items span {
  display: flex;
  align-items: center;
  color: #fff;
  margin: 1rem 0;
}
@media screen and (min-width: 480px) {
  .header-top .container .left-items span {
    margin: 0 1rem 0 0;
  }
}
.header-top .container .central-items {
  display: flex;
  flex: 1 30%;
  justify-content: center;
}
.header-top .container .central-items .header-logo {
  width: 120px;
  height: auto;
  transition: all 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .header-top .container .central-items .header-logo:hover {
    filter: brightness(1.3);
    transition: all 0.3s;
  }
}
.header-top .container .right-items {
  display: none;
  flex: 1 30%;
  justify-content: flex-end;
}
@media screen and (min-width: 480px) {
  .header-top .container .right-items {
    display: flex;
  }
}
.header-top .container .right-items span {
  display: flex;
  margin-left: 1rem;
  align-items: center;
  color: #fff;
}

.header {
  display: flex;
  align-items: center;
  padding: 2rem 0 6rem 0;
}
@media screen and (min-width: 480px) {
  .header {
    padding: 1rem 1rem 2rem 1rem;
  }
}
.header .container {
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.header .container .usercar, .header .container .cart-wrapper {
  display: none;
}
@media screen and (min-width: 480px) {
  .header .container {
    flex-direction: row;
  }
  .header .container .usercar, .header .container .cart-wrapper {
    display: flex;
  }
}
.header .container .usercar {
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: #fff;
  background: #1b2028;
  font-weight: 500;
  border: 1px solid #313b4f;
  padding: 0.6rem 1.2rem;
  border-radius: 0.3rem;
}
.header .container .usercar svg {
  font-size: 1.2rem;
  margin-right: 0.6rem;
}
.header .container .searchbox {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
}
@media screen and (min-width: 480px) {
  .header .container .searchbox {
    justify-content: center;
    flex-direction: row;
    width: 70%;
  }
}
.header .container .searchbox input {
  display: block;
  padding: 0.6rem;
  border: none;
  outline: 3px solid rgba(0, 90, 255, 0.15);
  border-radius: 0.3rem;
  width: 100%;
  margin-right: 0.6rem;
}
@media screen and (min-width: 480px) {
  .header .container .searchbox input {
    width: 80%;
  }
}
.header .container .searchbox button {
  margin-top: 1.2rem;
}
@media screen and (min-width: 480px) {
  .header .container .searchbox button {
    margin-top: unset;
  }
}
.header .container .cart-wrapper {
  position: relative;
  background: #1b2028;
}
.header .container .cart-wrapper .cart {
  cursor: pointer;
  background: #1b2028;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-items: flex-end;
  border: 1px solid #313b4f;
  padding: 0.6rem 1.2rem;
  z-index: 2;
  border-radius: 0.3rem;
}
@media (hover: hover) and (pointer: fine) {
  .header .container .cart-wrapper .cart:hover {
    background: #313b4f;
  }
}
.header .container .cart-wrapper .cart .cart-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .container .cart-wrapper .cart .cart-icon span {
  display: flex;
  justify-content: center;
  font-size: 0.6rem;
  font-weight: 600;
  background: #e30023;
  padding: 0.1rem;
  border-radius: 3rem;
  min-width: 0.8rem;
  position: relative;
  top: -0.3rem;
  left: -0.3rem;
}
.header .container .cart-wrapper .cart .cart-icon span::after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  left: -8px;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 8px solid rgba(227, 0, 35, 0.69);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: 1;
}
.header .container .cart-wrapper .cart .amount {
  margin-left: 0.4rem;
  font-weight: 600;
}
.header .container .cart-wrapper .cart .amount:after {
  content: " грн";
  text-transform: uppercase;
  font-size: 0.6rem;
}

.header-bottom {
  background: #1b2028;
  display: none;
}
@media screen and (min-width: 480px) {
  .header-bottom {
    display: flex;
  }
}
.header-bottom .nav {
  font-size: 0.8rem;
  text-transform: uppercase;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}
.header-bottom .nav li {
  list-style: none;
}
.header-bottom .nav li a {
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  padding: 1rem;
  display: block;
}

.button {
  display: flex;
  border: none;
  background: #005aff;
  border-radius: 0.3rem;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-left: 0.3rem;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  outline: 3px solid rgba(0, 90, 255, 0.15);
}
.button.clicked {
  background: #01ab64;
  outline: 3px solid rgba(1, 171, 100, 0.15);
}
.button.clicked:hover {
  filter: brightness(1);
  background: #01ab64;
  outline: 3px solid rgba(1, 171, 100, 0.15);
}
.button.clicked:hover svg {
  transform: translateX(-0.5rem);
}
.button svg {
  font-size: 1rem;
  transition: all 0.3s;
  transform: translateX(-0.5rem);
}
@media (hover: hover) and (pointer: fine) {
  .button:hover {
    transition: all 0.3s;
    filter: brightness(1.2);
    outline: 3px solid rgba(0, 90, 255, 0.25);
  }
  .button:hover svg {
    transform: translateX(-0.2rem);
    transition: all 0.3s;
  }
}

.product-filter {
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  flex: 0 30%;
  padding: 2rem 2rem;
  align-items: center;
  height: fit-content;
}
.product-filter h5 {
  align-self: flex-start;
  text-transform: uppercase;
}
.product-filter .filter-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.product-filter .filter-group select {
  padding: 0.6rem 0.8rem;
  margin-top: 1.4rem;
  width: 100%;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  z-index: 1;
  -moz-appearance: none;
  outline: 3px solid #eef1f5;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMjcuMzMgNDA0LjkxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMjcuMzMgNDA0LjkxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTExMC45Myw0MDUuNDJjLTYuMjctMS41MS0xMC4zOS01LjczLTE0LjEtMTAuNzJjLTMwLjU2LTQxLjAyLTYxLjItODEuOTktOTEuNzgtMTIzYy0xLjYtMi4xNS0zLjA3LTQuNDgtNC4xMi02Ljk0CgkJYy0yLjM3LTUuNTYtMC4wOC0xMC41Niw1LjY2LTEyLjM5YzIuNDEtMC43Niw1LjA0LTEuMjEsNy41Ni0xLjIxYzY2LjQyLTAuMDYsMTMyLjgzLTAuMDgsMTk5LjI1LDAuMDNjMi45MywwLDYuMDcsMC43NCw4Ljc1LDEuOTUKCQljNC43OCwyLjE1LDYuMzYsNi43OCw0LjI3LDExLjZjLTEuMDQsMi4zOS0yLjQ1LDQuNjgtNC4wMSw2Ljc3Yy0zMC45Miw0MS41LTYxLjg5LDgyLjk1LTkyLjgxLDEyNC40NQoJCWMtMy40Miw0LjU5LTcuNTIsOC4wMi0xMy4xNSw5LjQ1QzExNC42Miw0MDUuNDIsMTEyLjc3LDQwNS40MiwxMTAuOTMsNDA1LjQyeiIvPgoJPHBhdGggZD0iTTExNi4wNywwLjYyYzYuMzksMS4zOSwxMC42NSw1LjU5LDE0LjQzLDEwLjYzYzMwLjY2LDQwLjg2LDYxLjM3LDgxLjY4LDkyLjA3LDEyMi41YzIuMzMsMy4xLDQuMjEsNi4zNiw0LjM1LDEwLjM5CgkJYzAuMTIsMy40NS0xLjM5LDUuOTUtNC4yOSw3LjY3Yy0zLjI0LDEuOTItNi44NSwyLjI1LTEwLjUsMi4yNWMtMjguMDMsMC4wMS01Ni4wNSwwLTg0LjA4LDAuMDEKCQljLTM3Ljk4LDAuMDEtNzUuOTcsMC4wNi0xMTMuOTUtMC4wNGMtMi44Mi0wLjAxLTUuNzgtMC43My04LjQxLTEuNzljLTQuMy0xLjc0LTYuMTYtNS41MS00LjkyLTkuOTljMC43OC0yLjc4LDIuMDUtNS42MSwzLjc3LTcuOTIKCQljMTkuOTUtMjYuNzMsNDAuMDItNTMuMzYsNjAuMDctODAuMDJjMTAuODEtMTQuMzgsMjEuNjctMjguNzMsMzIuNDItNDMuMTZjMy43Mi01LDcuOTgtOS4xLDE0LjI4LTEwLjUxCgkJQzExMi45LDAuNjIsMTE0LjQ5LDAuNjIsMTE2LjA3LDAuNjJ6Ii8+CjwvZz4KPC9zdmc+Cg==");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.45em auto, 100%;
}
.product-filter .filter-group select:focus {
  border: 1px solid #005aff;
  outline: 3px solid rgba(0, 90, 255, 0.25);
}
.product-filter button.button {
  margin-top: 2rem;
}

.quantity {
  display: flex;
  align-items: center;
}
.quantity .quan-total {
  display: flex;
  font-weight: 500;
  width: 1rem;
  justify-content: center;
}
.quantity .quan-add, .quantity .quan-remove {
  border: none;
  display: flex;
  align-items: center;
  background: rgba(0, 90, 255, 0.15);
  border-radius: 50%;
  color: rgba(0, 90, 255, 0.25);
  padding: 0.3rem;
  margin: 0.3rem;
  transition: color 0s;
}
.quantity .active {
  transition: color 0s;
  background: #005aff;
  color: #fff;
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  .quantity .active:hover {
    filter: brightness(1.3);
  }
}

.product-code {
  font-size: 0.7rem;
  background: #eef1f5;
  color: #3e404d;
  padding: 0.1rem 0.3rem;
  border-radius: 0.3rem;
  width: fit-content;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(2, 2, 1);
  }
}

