.Slider {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.Slider-slides {
  display: flex;
}
.Slider-slides div img {
  width: 100%;
  height: 100%;
}

.Slider-slides--transition {
  transition: transform 0.25s;
}

.Slider-slides > * {
  flex-basis: 0;
  flex-grow: 1;
}

.Slider-slides > * {
  flex-basis: 0;
  flex-grow: 1;
}

.Slider-nav {
  align-content: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  order: 2;
}

.Slider-navButton {
  background: none;
  border: 0;
  height: 20px;
  opacity: 0.5;
  outline: none;
  position: relative;
  transition: opacity 0.25s;
  width: 20px;
  padding: 0;
}
.Slider-navButton::after {
  background: #345;
  border-radius: 50%;
  content: "";
  display: block;
  height: 6px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 6px;
}

.Slider-navButton--active {
  opacity: 1;
}
.Slider-navButton--active::after {
  height: 12px;
  left: 4px;
  opacity: 1;
  top: 4px;
  width: 12px;
}

.Slider-arrows {
  height: 0;
  left: 0;
  position: absolute;
  top: calc(50% - 35px);
  width: 100%;
  z-index: 1;
}

.Slider-arrows--noNav {
  top: calc(50% - 20px);
}

.Slider-arrow {
  background: none;
  border: none;
  height: 40px;
  opacity: 0.5;
  outline: none;
  position: absolute;
  transition: opacity 0.25s;
  padding: 0px;
  width: 21px;
}
.Slider-arrow:hover {
  opacity: 1;
}
.Slider-arrow::after, .Slider-arrow::before {
  background: #fff;
  border-radius: 3px;
  content: "";
  display: block;
  height: 23px;
  position: absolute;
  width: 5px;
  left: 8px;
}
.Slider-arrow::before {
  top: 0;
}
.Slider-arrow::after {
  bottom: 0;
}

.Slider-arrow--left {
  top: 0;
  left: 10px;
}
.Slider-arrow--left::before {
  transform: rotate(30deg);
}
.Slider-arrow--left::after {
  transform: rotate(-30deg);
}

.Slider-arrow--right {
  top: 0;
  right: 10px;
}
.Slider-arrow--right::before {
  transform: rotate(-30deg);
}
.Slider-arrow--right::after {
  transform: rotate(30deg);
}

