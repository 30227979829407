$--border-radius: .3rem;
$--header-bg-color: #1B2028FF;
$--secondary-color: #005aff;

.homepage {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
  .container {
    flex-direction: column;
    @media screen and (min-width : 480px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .homepage-slider {
      flex: 0 60%;

      .Slider {
        border-radius: $--border-radius;
        .Slider-inner {
          border-radius: $--border-radius;
        }
      }
    }
  }
  .makes{
    margin: 2rem 0;
    min-height: 300px;
  }
}
