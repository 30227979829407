$--border-radius: .3rem;
.pill{
  display: flex;
  font-weight: 600;
  font-size: .7rem;
  color: #005aff;
  background: rgba(0, 90, 255, 0.15);
  padding: .2rem .6rem;
  width: fit-content;
  border-radius: $--border-radius;
  text-transform: uppercase;
  align-items: center;
  flex-direction: row;
  svg{
    margin-right: .3rem;
  }
}
.product{
  display: flex;
  margin: 1rem;
  @media screen and (min-width : 480px) {
    margin: 2rem 0;
  }
  .container {
    flex-direction: column;
    @media screen and (min-width : 480px) {
      flex-direction: row;
    }
    .product-left {
      flex: 0 50%;
      max-width: 50%;

      .image-gallery-slides {
        border-radius: $--border-radius;
      }

      .image-gallery-thumbnail {
        border-radius: $--border-radius;
      }
    }

    .product-right {
      background: #fff;
      flex: 0 50%;
      padding: 1rem 2rem;
      margin-left: 1rem;
      border-radius: $--border-radius;

      .product-status {
        display: flex;
        margin: 1rem 0;

        .condition, .manufacturer {
          display: flex;
        }

        .manufacturer {
          margin-left: .6rem;
        }

        .original, .new {
          background: #01ab64;
          color: #fff;
        }
      }

      h1 {
        font-size: 1.4rem;
        font-weight: 800;
      }

      .product-footer {
        margin-top: 2rem;
        display: flex;
        align-items: center;
      }

      .price {
        color: #1b2028;
        display: flex;
        flex-direction: column;

        .item-price {
          font-size: 2.2rem;
          font-weight: 800;

          span {
            color: #3d4b62;
            text-transform: uppercase;
            font-size: 1rem;
            margin-left: .3rem;
          }
        }
      }
    }
  }
}
