#root{
  a{
    text-decoration: none;
  }
  .container{
    width: 100%;
    margin: 0 auto;
    display: flex;
    color: #1b2028;
    @media screen and (min-width : 480px) {
      width: 1140px;
    }
  }
}
