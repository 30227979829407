$--border-radius: .3rem;
$--main-color: #ffd400;
$--secondary-color: #005aff;
$--header-bg-color: #1b2028;
$--header-text-color: #fff;
*{
  transition: all .3s;
}
@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:-8px;
  left:-8px;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  border: 8px solid rgba(227, 0, 35, 0.69);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(.65,0,.34,1);
  z-index: 1;
}
.header-wrapper{
  background: rgb(27,32,40);
  background: radial-gradient(circle, rgb(25, 44, 73) 0%, rgb(29, 37, 51) 50%, rgba(27,32,40,1) 100%);
}
.header-top{
  display: flex;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: .8rem;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0 0 0;
  @media screen and (min-width : 480px) {
    padding: 1rem 0 0 0;
  }
  svg{
    margin-right: .3rem;
    color: $--main-color;// #7df386;
    font-size: 1rem;
  }
  .container {
    flex-direction: column-reverse;
    align-items: center;
    div{
      margin: .6rem 0;
    }
    @media screen and (min-width : 480px) {
      flex-direction: row;
    }
    .left-items {
      flex: 1 30%;
      display: none;
      @media screen and (min-width : 480px) {
        display: flex;
      }

      span {
        display: flex;
        align-items: center;
        color: $--header-text-color;
        margin: 1rem 0;
        @media screen and (min-width : 480px) {
          margin: 0 1rem 0 0;
        }
      }
    }

    .central-items {
      display: flex;
      flex: 1 30%;
      justify-content: center;

      .header-logo {
        width: 120px;
        height: auto;
        transition: all .3s;
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            filter: brightness(1.3);
            transition: all .3s;
          }
        }
      }
    }

    .right-items {
      display: none;
      flex: 1 30%;
      justify-content: flex-end;
      @media screen and (min-width : 480px) {
        display: flex;
      }
      span {
        display: flex;
        margin-left: 1rem;
        align-items: center;
        color: $--header-text-color;
      }
    }
  }
}
.header{
  //background: $--header-bg-color;
  display: flex;
  align-items: center;
  padding: 2rem 0 6rem 0;
  @media screen and (min-width : 480px) {
    padding: 1rem 1rem 2rem 1rem;
  }
  .container {
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .usercar, .cart-wrapper{
      display: none;
    }
    @media screen and (min-width : 480px) {
      flex-direction: row;
      .usercar, .cart-wrapper{
        display: flex;
      }
    }
    .usercar{
      justify-content: center;
      align-items: center;
      font-size: .8rem;
      color: #fff;
      background: $--header-bg-color;
      font-weight: 500;
      border: 1px solid #313b4f;
      padding: .6rem 1.2rem;
      border-radius: $--border-radius;
      svg{
        font-size: 1.2rem;
        margin-right: .6rem;
      }
    }
    .searchbox {
      display: flex;
      flex-direction: column;
      width: 80%;
      @media screen and (min-width : 480px) {
        justify-content: center;
        flex-direction: row;
        width: 70%;
      }

      input {
        display: block;
        padding: .6rem;
        border: none;
        outline: 3px solid rgba(0, 90, 255, 0.15);
        border-radius: $--border-radius;
        width: 100%;
        margin-right: .6rem;
        @media screen and (min-width : 480px) {
          width: 80%;
        }
      }
      button{
        margin-top: 1.2rem;
        @media screen and (min-width : 480px) {
          margin-top: unset;
        }
      }
    }
    .cart-wrapper{
      position: relative;
      background: $--header-bg-color;
      .cart {
        cursor: pointer;
        background: $--header-bg-color;
        position: relative;
        color: #fff;
        display: flex;
        align-items: center;
        justify-items: flex-end;
        border: 1px solid #313b4f;
        padding: .6rem 1.2rem;
        z-index: 2;
        border-radius: $--border-radius;
        @media (hover: hover) and (pointer: fine) {
          &:hover{
            background: #313b4f;
          }
        }
        .cart-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            display: flex;
            justify-content: center;
            font-size: .6rem;
            font-weight: 600;
            background: #e30023;
            padding: .1rem;
            border-radius: 3rem;
            min-width: .8rem;
            position: relative;
            top: -.3rem;
            left: -.3rem;
            &::after {
              @include rings(1s, 0s);
            }
          }
        }

        .amount {
          margin-left: .4rem;
          font-weight: 600;

          &:after {
            content: " грн";
            text-transform: uppercase;
            font-size: .6rem;
          }
        }
      }

    }
  }
}
.header-bottom{
  background: $--header-bg-color;
  display: none;
  @media screen and (min-width : 480px) {
    display: flex;
  }
  .nav{
    font-size: .8rem;
    text-transform: uppercase;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    li{
      list-style: none;
      a{
        text-decoration: none;
        font-weight: 600;
        color: #fff;
        padding: 1rem;
        display: block;
      }
    }
  }
}
.button {
  display: flex;
  border: none;
  background: $--secondary-color;
  border-radius: $--border-radius;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: .8rem;
  margin-left: .3rem;
  padding: .6rem 1.2rem;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all .3s;
  outline: 3px solid rgba(0, 90, 255, 0.15);
  &.clicked{
    background: #01ab64;
    outline: 3px solid rgba(1, 171, 100, 0.15);
    &:hover{
      filter: brightness(1);
      background: #01ab64;
      outline: 3px solid rgba(1, 171, 100, 0.15);
      svg{
        transform: translateX(-.5rem);
      }
    }
  }
  svg {
    font-size: 1rem;
    transition: all .3s;
    transform: translateX(-.5rem);
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover{
      transition: all .3s;
      filter: brightness(1.2);
      outline: 3px solid rgba(0, 90, 255, 0.25);
      svg{
        transform: translateX(-.2rem);
        transition: all .3s;
      }
    }
  }
}
.product-filter {
  border-radius: $--border-radius;
  display: flex;
  flex-direction: column;
  background: #fff;
  flex: 0 30%;
  padding: 2rem 2rem;
  align-items: center;
  height: fit-content;
  h5{
    align-self: flex-start;
    text-transform: uppercase;
  }
  .filter-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    select{
      //outline: none;
      padding: .6rem .8rem ;
      margin-top: 1.4rem;
      width: 100%;
      border-radius: $--border-radius;
      font-size: .8rem;
      z-index: 1;
      -moz-appearance: none;
      outline: 3px solid #eef1f5;
      -webkit-appearance: none;
      appearance: none;
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMjcuMzMgNDA0LjkxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMjcuMzMgNDA0LjkxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTExMC45Myw0MDUuNDJjLTYuMjctMS41MS0xMC4zOS01LjczLTE0LjEtMTAuNzJjLTMwLjU2LTQxLjAyLTYxLjItODEuOTktOTEuNzgtMTIzYy0xLjYtMi4xNS0zLjA3LTQuNDgtNC4xMi02Ljk0CgkJYy0yLjM3LTUuNTYtMC4wOC0xMC41Niw1LjY2LTEyLjM5YzIuNDEtMC43Niw1LjA0LTEuMjEsNy41Ni0xLjIxYzY2LjQyLTAuMDYsMTMyLjgzLTAuMDgsMTk5LjI1LDAuMDNjMi45MywwLDYuMDcsMC43NCw4Ljc1LDEuOTUKCQljNC43OCwyLjE1LDYuMzYsNi43OCw0LjI3LDExLjZjLTEuMDQsMi4zOS0yLjQ1LDQuNjgtNC4wMSw2Ljc3Yy0zMC45Miw0MS41LTYxLjg5LDgyLjk1LTkyLjgxLDEyNC40NQoJCWMtMy40Miw0LjU5LTcuNTIsOC4wMi0xMy4xNSw5LjQ1QzExNC42Miw0MDUuNDIsMTEyLjc3LDQwNS40MiwxMTAuOTMsNDA1LjQyeiIvPgoJPHBhdGggZD0iTTExNi4wNywwLjYyYzYuMzksMS4zOSwxMC42NSw1LjU5LDE0LjQzLDEwLjYzYzMwLjY2LDQwLjg2LDYxLjM3LDgxLjY4LDkyLjA3LDEyMi41YzIuMzMsMy4xLDQuMjEsNi4zNiw0LjM1LDEwLjM5CgkJYzAuMTIsMy40NS0xLjM5LDUuOTUtNC4yOSw3LjY3Yy0zLjI0LDEuOTItNi44NSwyLjI1LTEwLjUsMi4yNWMtMjguMDMsMC4wMS01Ni4wNSwwLTg0LjA4LDAuMDEKCQljLTM3Ljk4LDAuMDEtNzUuOTcsMC4wNi0xMTMuOTUtMC4wNGMtMi44Mi0wLjAxLTUuNzgtMC43My04LjQxLTEuNzljLTQuMy0xLjc0LTYuMTYtNS41MS00LjkyLTkuOTljMC43OC0yLjc4LDIuMDUtNS42MSwzLjc3LTcuOTIKCQljMTkuOTUtMjYuNzMsNDAuMDItNTMuMzYsNjAuMDctODAuMDJjMTAuODEtMTQuMzgsMjEuNjctMjguNzMsMzIuNDItNDMuMTZjMy43Mi01LDcuOTgtOS4xLDE0LjI4LTEwLjUxCgkJQzExMi45LDAuNjIsMTE0LjQ5LDAuNjIsMTE2LjA3LDAuNjJ6Ii8+CjwvZz4KPC9zdmc+Cg==');
      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .45em auto, 100%;
      &:focus{
        border: 1px solid $--secondary-color;
        outline: 3px solid rgba(0, 90, 255, 0.25);
      }
    }

  }
  button.button{
    margin-top: 2rem;
  }
}
.quantity{
  display: flex;
  align-items: center;
  .quan-total{
    display: flex;
    font-weight: 500;
    width: 1rem;
    justify-content: center;
  }
  .quan-add, .quan-remove{
    border: none;
    display: flex;
    align-items: center;
    background: rgba(0, 90, 255, 0.15);
    border-radius: 50%;
    color: rgba(0, 90, 255, 0.25);
    padding: .3rem;
    margin: .3rem;
    transition: color 0s;
  }
  .active{
    transition: color 0s;
    background: #005aff;
    color: #fff;
    cursor: pointer;
    @media (hover: hover) and (pointer: fine) {
      &:hover{
        filter: brightness(1.3);
      }
    }
  }
}
.product-code{
  font-size: .7rem;
  background: #eef1f5;
  color: #3e404d;
  padding: .1rem .3rem;
  border-radius: $--border-radius;
  width: fit-content;
}
@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }

  to {
    opacity: 0;
    transform: scale3d(2,2,1);
  }
}
