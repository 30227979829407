$--border-radius: .3rem;
.container {
  &.column {
    flex-direction: column;
  }
  .checkout_form{
    flex-direction: row;
    display: flex;
    .checkout{
      width: 50%;
      .box{
        input {
          display: block;
          padding: .6rem;
          margin: 1rem .5rem;
          outline: 3px solid rgba(0, 0, 0, 0.05);
          border: 1px solid rgba(0, 0, 0, 0.05);
          border-radius: $--border-radius;
          width: 100%;
          &:focus{
            outline: 3px solid rgba(0, 90, 255, 0.15);
            border: 1px solid rgb(0, 90, 255)
          }
          @media screen and (min-width : 480px) {
            width: 80%;
          }
        }
      }
    }
  }
  .products-list {
    overflow-y: scroll;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    border-radius: $--border-radius;
    padding: .4rem;
    background: #f0f3f5;

    .checkout-product {
      margin: .4rem;
      padding: .4rem;
      background: #fff;
      border-radius: $--border-radius;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .title {
        flex: 0 calc(30% - 1rem);
        margin-left: 1rem;
      }

      .prod-code {
        flex: 0 calc(25% - 1rem);
        margin-left: 1rem;
      }

      .quantity {
        flex: 0 calc(10% - 1rem);
        margin-left: 1rem;
        min-width: 50px;
      }

      .delete {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        flex: 0 5%;
        font-size: 1.2rem;
        min-width: 40px;
      }

      img {
        flex: 0 10%;
        width: 10%;
        height: auto;
      }
    }
  }

  .cart-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .8rem;

    .right-items {
      display: flex;
      align-items: center;
    }

    .cart-total {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 1rem;
      margin-right: 1rem;

      .total {
        font-size: 1.2rem;
        font-weight: 600;

        &:after {
          content: " грн";
          text-transform: uppercase;
          font-size: .6rem;
        }
      }
    }
  }
}