$--border-radius: .3rem;
.item{
  display: flex;
  flex-direction: column;
  padding: .8rem;
  justify-content: center;
  align-items: center;
  border-radius: $--border-radius;
  background: #fff;
  color: #1b2028;
  font-size: .8rem;
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;
  margin: 0.6rem 0.6rem 0 0;
  outline: 3px solid transparent;
  width: calc(20% - 2.2rem);
  min-width: 100px;
  @media screen and (min-width : 480px) {
    width: unset;
    margin: 1rem 1rem 0 0;
  }
  img{
    width: 60px;
    height: auto;
    margin-bottom: 1rem;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover{
      outline: 3px solid rgba(0, 90, 255, 1)
    }
  }
}
