.notfound {
  background: url("./carwheel.svg") no-repeat center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
.notfound h3 {
  font-size: 4rem;
  padding: 0;
  margin: 0;
}
.notfound a {
  text-decoration: none;
  color: #fff;
}

