$--border-radius: .3rem;
.popup-overlay{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  .overlay-bg{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.35);
    z-index: 998;
  }
  .popup{
    left: 0;
    top: 0;
    width: 70%;
    max-height: 60vh;
    padding: 2rem;
    position: relative;
    z-index: 1000;
    background: #fff;
    border-radius: $--border-radius;
    .popup-header{
      display: flex;
      width: 100%;
      text-transform: uppercase;
      font-weight: 600;
      justify-content: space-between;
      align-items: center;
      svg{
        font-size: 1.6rem;
        cursor: pointer;
      }
    }
    .popup-body {
      .popup-cart {
        .products-list {
          overflow-y: scroll;
          margin: 1rem 0;
          display: flex;
          flex-direction: column;
          max-height: 40vh;
          border-radius: $--border-radius;
          padding: .4rem;
          background: #f0f3f5;

          .cart-popup-product {
            margin: .4rem;
            padding: .4rem;
            background: #fff;
            border-radius: $--border-radius;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .title{
              flex: 0 calc(50% - 1rem);
              margin-left: 1rem;
            }
            .prod-code{
              flex: 0 calc(25% - 1rem);
              margin-left: 1rem;
            }
            .quantity{
              flex: 0 calc(10% - 1rem);
              margin-left: 1rem;
              min-width: 50px;
            }
            .delete{
              display: flex;
              cursor: pointer;
              align-items: center;
              justify-content: center;
              flex: 0 5%;
              font-size: 1.2rem;
              min-width: 40px;
            }
            img {
              flex: 0 10%;
              width: 10%;
              height: auto;
            }
          }
        }
        .cart-footer{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: .8rem;
          .right-items{
            display: flex;
            align-items: center;
          }
          .cart-total {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            line-height: 1rem;
            margin-right: 1rem;
            .total {
              font-size: 1.2rem;
              font-weight: 600;
              &:after {
                content: " грн";
                text-transform: uppercase;
                font-size: .6rem;
              }
            }
          }
        }
      }
    }
  }
}
