.homepage {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
}
.homepage .container {
  flex-direction: column;
}
@media screen and (min-width: 480px) {
  .homepage .container {
    flex-direction: row;
    justify-content: space-between;
  }
}
.homepage .container .homepage-slider {
  flex: 0 60%;
}
.homepage .container .homepage-slider .Slider {
  border-radius: 0.3rem;
}
.homepage .container .homepage-slider .Slider .Slider-inner {
  border-radius: 0.3rem;
}
.homepage .makes {
  margin: 2rem 0;
  min-height: 300px;
}

