.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.footer .container {
  flex-direction: column;
}
@media screen and (min-width: 480px) {
  .footer .container {
    flex-direction: row;
  }
}
.footer .container .footer-bottom {
  display: flex;
  border-top: 1px solid #c5c7cb;
  width: 100%;
  justify-content: center;
  padding: 1rem 0;
}
.footer .container .footer-bottom span {
  font-size: 0.8rem;
  color: #797c8a;
}
.footer .container .footer-logo {
  width: 90px;
  margin-right: 1rem;
}
.footer .container .left-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 0 25%;
  color: #3e404d;
  font-size: 0.8rem;
}
@media screen and (min-width: 480px) {
  .footer .container .left-items {
    flex-direction: row;
  }
}
.footer .container .central-items {
  display: flex;
  flex: 0 45%;
  justify-content: center;
  align-items: center;
}
.footer .container .central-items img {
  opacity: 0.8;
  margin: 1rem;
  height: 30px;
}
.footer .container .central-items img:nth-child(2) {
  height: 20px;
}
.footer .container .right-items {
  display: flex;
  flex: 0 30%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (min-width: 480px) {
  .footer .container .right-items {
    flex-direction: row;
  }
}
.footer .container .right-items span {
  color: #3e404d;
  font-size: 0.8rem;
  margin: 1rem;
}

