.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    margin: 0 0 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
}
@media screen and (min-width : 480px) {
    .react-tabs__tab-list {
        flex-direction: row;
    }
}
.react-tabs__tab {
    display: inline-block;
    border-bottom: 2px solid rgba(0, 90, 255, 0.15);
    font-weight: 600;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    text-transform: uppercase;
}

.react-tabs__tab--selected {
    border-width: 3px;
    border-color: rgba(0, 90, 255, 1);
    border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: flex;
    flex-wrap: wrap;
}
